import './hire-us.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from 'components/layouts/Standard';

const HireUsPage: React.FC = () => {
  const {
    allClientsYaml: { clients }
  } = useStaticQuery(graphql`
    query HireUsPageQuery {
      allClientsYaml {
        clients: nodes {
          name
        }
      }
    }
  `) as {
    allClientsYaml: {
      clients: Array<{
        name: string;
      }>;
    };
  };

  return (
    <Layout
      title="Hire Us &mdash; Friends of The Web"
      tagline="works with clients all over the world from a wide variety of industries. Together, we can make something beautiful."
    >
      <div className="contact-content">
        <p>If you’d like to discuss a project, please get in&nbsp;touch.</p>
        <div className="contact-info">
          <ul className="contact-links">
            <li>
              <a href="tel:4439373389">443-937-3389</a>
            </li>
            <li>
              <a href="mailto:&#104;&#101;&#108;&#108;&#111;&#64;&#102;&#114;&#105;&#101;&#110;&#100;&#115;&#111;&#102;&#116;&#104;&#101;&#119;&#101;&#98;&#46;&#99;&#111;&#109;">
                &#104;&#101;&#108;&#108;&#111;&#64;&#102;&#114;&#105;&#101;&#110;&#100;&#115;&#111;&#102;&#116;&#104;&#101;&#119;&#101;&#98;&#46;&#99;&#111;&#109;
              </a>
            </li>
            <li>
              <a href="http://twitter.com/friendsoftheweb">@friendsoftheweb</a>
            </li>
          </ul>
        </div>

        <h3> Our Past Clients &amp; Collaborators</h3>
        <ul className="client-list">
          {clients.map((client) => (
            <li className="client-list-item" key={client.name}>
              {client.name}
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  );
};

export default HireUsPage;
